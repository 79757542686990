import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "organizationAddActivity",
    "modal-title": "Add activity",
    size: "small",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activitiesList, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "lh-40 body-text-semi-bold-14 pointer d-flex align-center",
          onClick: ($event: any) => (_ctx.activityClickHandler(item.name))
        }, [
          _createVNode(_component_tm_icon, {
            class: "mr-2 distinct--text",
            name: item.icon
          }, null, 8, ["name"]),
          _createTextVNode(" " + _toDisplayString(item.name), 1)
        ], 8, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }))
}