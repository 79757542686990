
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import { useModals } from '@/compositions/modals'
import { useRouter } from 'vue-router'

type ActivityItemType = {
  name: string;
  icon: string;
}

export default defineComponent({
  components: {
    TmModal,
  },
  props: {
    organization: {
      type: Boolean,
    },
  },
  setup() {
    const { initCall } = useContactsOpenModal()

    const router = useRouter()
    const { openModal, closeModal } = useModals()

    const activitiesList: ActivityItemType[] = [
      { name: 'Add note', icon: 'article' },
      { name: 'Make a call', icon: 'phone' },
      { name: 'Send an email', icon: 'email' },
      { name: 'Send SMS', icon: 'chat' },
    ]

    const activityClickHandler = (name: string): void => {
      closeModal('organizationAddActivity')
      switch (name) {
        case 'Add note': openModal('note', { modalTitle: 'Add organization note' }); break
        case 'Make a call': initCall(); break
        case 'Send an email': break
        default: router.push({ name: 'base.compose.message' })
      }
    }

    return {
      activityClickHandler,
      activitiesList,
    }
  },
})
